<template>
  <div class="auth-wrapper px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <validation-observer
          ref="verifyMetadataForm"
          v-slot="{passes}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="passes(submitMetadata)"
          >

            <b-row>
              <!-- Next of Kin -->
              <b-col
                v-if="member_type !== 2 && member_type !== 3"
                lg="6"
                sm="12"
              >
                <h4>Next of Kin Details</h4>
                <!-- first name -->
                <b-form-group
                  label-for="first_name"
                  :label="labels.next_of_kin_first_name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Next of Kin's First Name"
                    vid="first_name"
                  >
                    <b-form-input
                      id="next-of-kin-first-name"
                      v-model="kin_information.first_name"
                      name="next-of-kin-first-name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Jane"
                      autofocus
                      type="text"
                      onkeydown="return /[a-z]/i.test(event.key)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Last Name -->
                <b-form-group
                  label-for="last_name"
                  :label="labels.next_of_kin_last_name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Next of Kin's Last Name"
                    vid="last_name"
                  >
                    <b-form-input
                      id="next-of-kin-last-name"
                      v-model="kin_information.last_name"
                      name="next-of-kin-last-name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Doe"
                      type="text"
                      onkeydown="return /[a-z]/i.test(event.key)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Phone Number -->
                <b-form-group
                  label-for="phone_number"
                  :label="labels.next_of_kin_phone_number"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Next of Kin's Phone Number"
                    vid="phone_number"
                  >
                    <b-form-input
                      id="next-of-kin-phone-number"
                      v-model="kin_information.phone_number"
                      name="next-of-kin-phone-number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="07********"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- ID Number -->
                <!--
                <b-form-group
                  label-for="id_number"
                  label="Please enter the Next of Kin's ID Number"
                >
                  <b-form-input
                    id="next-of-kin-id-number"
                    v-model="kin_information.id_number"
                    name="next-of-kin-id-number"
                    placeholder="********"
                  />
                </b-form-group>
                -->

                <!-- Relationship -->
                <b-form-group
                  label-for="relationship"
                  :label="labels.next_of_kin_relationship"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Next of Kin Relationship"
                    vid="relationship"
                  >
                    <b-form-input
                      id="next-of-kin-relationship"
                      v-model="kin_information.relationship"
                      name="next-of-kin-relationship"
                      placeholder="Enter your relationship"
                      type="text"
                      onkeydown="return /[a-z]/i.test(event.key)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Kin Identification Document -->
                <b-form-group
                  label-for="identification document"
                  :label="labels.next_of_kin_id_document"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Next of Kin ID Document"
                    vid="id_document"
                  >
                    <b-form-file
                      id="next-of-kin-identification-document"
                      v-model="kin_information.identification_document"
                      name="next-of-kin-identification-document"
                      accept=".pdf"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-show="show_kin_id_document_size_error"
                      class="text-danger"
                    >File Too Large(Max 5MB)</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Payment Information -->
              <b-col
                :lg="member_type != 2 ? 6 : 12"
                sm="12"
              >
                <h4>Payment Information</h4>
                <h5>Bank Details</h5>
                <!-- Bank Name -->
                <b-form-group
                  label-for="bank_name"
                  label="Select Bank Name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bank Name"
                    vid="bank_name"
                    :rules="require_bank_details ? 'required' : ''"
                  >
                    <b-form-select
                      id="bank-name"
                      v-model="payment_information.bank_name"
                      :options="banks_options"
                      name="bank-name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Bank Account Name -->
                <b-form-group
                  label-for="bank_account_name"
                  label="Enter Bank Account Name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bank Account Name"
                    vid="bank_account_name"
                    :rules="require_bank_details ? 'required' : ''"
                  >
                    <b-form-input
                      id="bank-account-name"
                      v-model="payment_information.bank_account_name"
                      name="bank-account-name"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Bank Account Number -->
                <b-form-group
                  label-for="bank_account_number"
                  label="Enter Bank Account Number"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bank Account number"
                    vid="bank_account_number"
                    :rules="require_bank_details ? 'required' : ''"
                  >
                    <b-form-input
                      id="bank-account-number"
                      v-model="payment_information.bank_account_number"
                      name="bank-account-number"
                      :state="errors.length > 0 ? false:null"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Bank Branch -->
                <b-form-group
                  label-for="bank_branch"
                  label="Enter Bank Branch"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bank Branch"
                    vid="bank_branch"
                    :rules="require_bank_details ? 'required' : ''"
                  >
                    <b-form-input
                      id="bank-branch"
                      v-model="payment_information.bank_branch"
                      name="bank-branch"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      onkeydown="return /[a-z]/i.test(event.key)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <h5>Mobile Money</h5>
                <!-- Mobile Money Phone Number -->
                <b-form-group
                  label-for="mobile_money_phone_number"
                  label="Enter Mobile Money Registered Phone Number"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Mobile Money Phone Number"
                    vid="mobile_money_phone_number"
                  >
                    <b-form-input
                      id="mobile-money-phone-number"
                      v-model="payment_information.mobile_money_phone_number"
                      name="mobile-money-phone-number"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-show="!isValidPhoneNumber"
                      class="text-danger"
                    >Enter a valid phone number</small>
                  </validation-provider>
                </b-form-group>

                <!-- Mobile Money Provider -->
                <b-form-group
                  label-for="mobile_money_provider"
                  label="Select Mobile Money Service Provider"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Mobile Money Provider"
                    vid="mobile_money_provider"
                  >
                    <b-form-select
                      id="mobile-money-provider"
                      v-model="payment_information.mobile_money_provider"
                      name="mobile-money-provider"
                      :options="mobile_service_providers"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Mobile Money Registered Name -->
                <b-form-group
                  label-for="mobile_money_registered_name"
                  label="Enter Mobile Money Registered Name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Mobile Money Registered Name"
                    vid="mobile_money_registered_name"
                  >
                    <!-- With number input checker -->
                    <!-- <b-form-input
                      id="mobile-money-registered-name"
                      v-model="payment_information.mobile_money_registered_name"
                      name="mobile-money-registered-name"
                      type="text"
                      onkeydown="return /[a-z]/i.test(event.key)"
                      :state="errors.length > 0 ? false:null"
                    /> -->
                    <!-- Without number checker -->
                    <b-form-input
                      id="mobile-money-registered-name"
                      v-model="payment_information.mobile_money_registered_name"
                      name="mobile-money-registered-name"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-show="show_enter_valid_mobile_money_name"
                      class="text-danger"
                    >Enter a valid name</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="isLoading"
              class="w-25 float-right"
            >
              Submit
              <b-spinner
                v-if="isLoading"
                small
              />
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BButton, BForm, BFormInput, BFormGroup, BCard, BSpinner, BFormFile, BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BSpinner,
    BFormFile,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      // Member Type
      member_type: '',
      banks_options: [],
      mobile_service_providers: [],

      // Kin information
      kin_information: {
        first_name: '',
        last_name: '',
        phone_number: '',
        id_number: '',
        relationship: '',
        identification_document: null,
      },

      show_kin_id_document_size_error: false,
      show_enter_valid_mobile_money_name: false,
      isValidPhoneNumber: true,

      // Payment information
      payment_information: {
        bank_account_name: '',
        bank_account_number: '',
        bank_branch: '',
        bank_name: '',
        mobile_money_provider: '',
        mobile_money_registered_name: '',
        mobile_money_phone_number: '',
      },

      // validation rules
      required,

      isLoading: false,
      isResendingCode: false,
      labels: {
        next_of_kin_first_name: 'Enter Next of Kin\'s First Name',
        next_of_kin_last_name: 'Enter Next of Kin\'s Last Name',
        next_of_kin_phone_number: 'Enter Next of Kin\'s Phone Number',
        next_of_kin_relationship: 'Enter Relationship',
        next_of_kin_id_document: 'Upload Next of Kin\'s National ID, Passport or Birth Certificate',
      },
      require_bank_details: false,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'kin_information.identification_document': function (newDoc) {
      if (newDoc.size > 5000000) {
        this.show_kin_id_document_size_error = true
      } else {
        this.show_kin_id_document_size_error = false
      }
    },
    // eslint-disable-next-line func-names
    'payment_information.mobile_money_phone_number': function (phoneNumber) {
      const validationRegex = /^\d{10}$/
      if (phoneNumber.match(validationRegex)) {
        this.isValidPhoneNumber = true
      } else {
        this.isValidPhoneNumber = false
      }
    },
  },
  async mounted() {
    this.require_bank_details = localStorage.getItem('require_bank_details')
    this.member_type = JSON.parse(localStorage.getItem('user')).member_type_id
    if (this.member_type === 4) {
      this.labels.next_of_kin_first_name = 'Enter the Minor\'s Next of Kin\'s First Name'
      this.labels.next_of_kin_last_name = 'Enter the Minor\'s Next of Kin\'s Last Name'
      this.labels.next_of_kin_phone_number = 'Enter the Minor\'s Next of Kin\'s Phone Number'
      this.labels.next_of_kin_relationship = 'Enter the Next of Kin Relationship to the minor'
    } else if (this.member_type === 5) {
      this.labels.next_of_kin_first_name = 'Enter the Deceased Person\'s Next of Kin\'s First Name'
      this.labels.next_of_kin_last_name = 'Enter the Deceased Person\'s Next of Kin\'s Last Name'
      this.labels.next_of_kin_phone_number = 'Enter the Deceased person\'s Next of Kin\'s Phone Number'
      this.labels.next_of_kin_relationship = 'Enter the Next of Kin Relationship to the deceased person'
    }
    await this.$http
      .get('/banks')
      .then(response => {
        response.data.data.forEach(bank => {
          this.banks_options.push({ text: bank.name, value: bank.name })
        })
      })
      .catch(error => {
        console.log(error)
      })
    await this.$http
      .get('/mobile-service-providers')
      .then(response => {
        response.data.forEach(provider => {
          this.mobile_service_providers.push({ text: provider.name, value: provider.name })
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    submitMetadata() {
      if (this.member_type !== 2 && this.kin_information.identification_document.size > 5000000) {
        this.show_kin_id_document_size_error = true
        return
      }
      const validationRegex = /^\d{10}$/
      if (this.payment_information.mobile_money_phone_number !== '' && !this.payment_information.mobile_money_phone_number.match(validationRegex)) {
        this.isValidPhoneNumber = false
        return
      }
      if (this.payment_information.mobile_money_registered_name !== '' && /[a-z]/i.test(this.payment_information.mobile_money_registered_name) === false) {
        this.show_enter_valid_mobile_money_name = true
        return
      }
      this.isLoading = true
      const formData = new FormData()
      formData.append('member_type', this.member_type)
      Object.keys(this.kin_information).some(key => formData.append(key, this.kin_information[key]))
      Object.keys(this.payment_information).some(key => formData.append(key, this.payment_information[key]))
      this.$http.post('/membership/metadata/upload', formData)
        .then(() => {
        // this.$router.replace(getHomeRouteForLoggedInUser(permissions[0]))
          this.$router.replace({ name: 'register-content-upload' })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Data uploaded successfully!',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            })
        })
        .catch(error => {
          console.log(error.response.data.data)
          this.$refs.verifyMetadataForm.setErrors(error.response.data.data)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'The data entered is invalid.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isLoading = false
        })
      // this.$refs.verifyMetadataForm.validate().then(success => {
      //   if (success) {
      //   }
      // })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
